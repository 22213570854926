import { Container, Nav, Navbar, Spinner } from 'react-bootstrap'
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'

export default function CustomNav() {
  const { user } = useAuthContext()
  const { logout, error, isPending } = useLogout()

  return (
    <Navbar sticky="top" bg="dark" variant="dark" expand="lg">
      <Container className="px-3">
        <Navbar.Brand href="/" className="">
          Philips Dashboard
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {!user && <Nav.Link href="/login">Log in</Nav.Link>}
            {user && (
              <>
                {isPending ? (
                  <Nav.Link href="" disabled>
                    <Spinner animation="border" variant="white" />
                  </Nav.Link>
                ) : (
                  <Nav.Link href="" onClick={logout}>
                    Log out
                  </Nav.Link>
                )}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

// chartjs
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Card } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function StarRatings({ reviewData, colors }) {
  const options = {
    responsive: true,
    scales: {
      yAxis: {
        max: 5,
        min: 0,
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }

  // Week numbers
  const labels = reviewData.map((review) => review.id)

  // Find unique products
  const allLabels = new Set()
  reviewData.forEach((element) => {
    Object.keys(element).forEach((key) => {
      if (key !== 'id' && key !== 'createdAt') {
        allLabels.add(key)
      }
    })
  })

  // Compile 'data' for each product
  const datasets = []
  allLabels.forEach((label) => {
    // We loop according to each label
    // Build an array for the data found in each week for this label
    const dataForLabel = reviewData.map((week) => {
      if (week[label].stars) {
        return week[label].stars
      } else {
        // In case new items are added later on and they don't have any data
        return 0
      }
    })

    // Colors will be added after data set is sorted
    datasets.push({
      label,
      data: dataForLabel,
    })
  })

  // This is the order these items need to be in
  // Might move up a level so all other components can use it
  const orderBy = [
    'Active Bottle',
    'Everyday Bottle',
    'Smart Bottle',
    'Soda Maker',
  ]

  // Order the data by the orderBy Arr
  datasets.sort((a, b) => orderBy.indexOf(a.label) - orderBy.indexOf(b.label))

  // Now that it's been sorted, we add the colors to the datasets
  datasets.forEach((d, ind) => {
    d.borderColor = `rgb(${colors[ind]})`
    d.backgroundColor = `rgb(${colors[ind]}, 0.5)`
  })

  // Compile data for chart
  const data = { labels, datasets }

  return (
    <Card className="shadow p-3">
      <h3 className="text-center mt-3 mb-4">Amazon Reviews - Star ratings</h3>
      <Line options={options} data={data} />
    </Card>
  )
}

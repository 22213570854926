import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// pages and components
import CustomNav from './components/CustomNav'
import Dashboard from './pages/dashboard/Dashboard'
import Login from './pages/login/Login'

// styles
import './App.css'

function App() {
  const { user, authIsReady } = useAuthContext()

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <CustomNav />
          <Routes>
            <Route
              path="/"
              element={user ? <Dashboard /> : <Navigate to="/login" />}
            ></Route>
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            ></Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  )
}

export default App

import { useEffect, useRef, useState } from 'react'

import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap'

import format from 'date-fns/format'

import StarRatings from './StarRatings'
import StarRatingsDelta from './StarRatingsDelta'
import WeeklyRatingsCount from './WeeklyRatingsCount'
import WeeklyProductComplaints from './WeeklyProductComplaints'

import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'
import { useFirestore } from '../../hooks/useFirestore'

// function randomIntFromInterval(min, max) {
//   return Math.floor(Math.random() * (max - min + 1) + min)
// }

function hsvToRgb(h, s, v) {
  var r, g, b

  var i = Math.floor(h * 6)
  var f = h * 6 - i
  var p = v * (1 - s)
  var q = v * (1 - f * s)
  var t = v * (1 - (1 - f) * s)

  switch (i % 6) {
    case 0:
      r = v
      g = t
      b = p
      break
    case 1:
      r = q
      g = v
      b = p
      break
    case 2:
      r = p
      g = v
      b = t
      break
    case 3:
      r = p
      g = q
      b = v
      break
    case 4:
      r = t
      g = p
      b = v
      break
    case 5:
      r = v
      g = p
      b = q
      break
    default:
      break
  }

  return [r * 255, g * 255, b * 255]
}

function getColorSpread(total) {
  var i = 1 / total // distribute the colors evenly on the hue range
  var colors = [] // hold the generated colors
  for (var x = 0; x < total; x++) {
    colors.push(hsvToRgb(i * x, 1, 0.8).toString())
  }
  return colors
}

export default function Dashboard() {
  const { user } = useAuthContext()
  const [updateDate, setUpdateDate] = useState(null)

  const { addDocument, deleteDocument, response } =
    useFirestore('Star Ratings Data')
  const { documents: starRatings, error: starError } =
    useCollection('Star Ratings Data')
  const { documents: complaints, error: complaintsError } =
    useCollection('Weekly Complaints')

  const colors = getColorSpread(4)

  const [formError, setFormError] = useState('')
  const [showNewEntriesModal, setShowNewEntriesModal] = useState(false)
  const [showDeleteLastWeekEntryModal, setShowDeleteLastWeekEntryModal] =
    useState(false)
  const [weekNumber, setWeekNumber] = useState('')

  let allLabels = useRef([])
  const initialFormData = useRef({})

  const [formData, setFormData] = useState(null)

  // Begin by getting the last star ratings delta, since it will have
  // the most up-to-date products
  useEffect(() => {
    if (complaints) {
      const lastDate = complaints[0].createdAt.seconds

      setUpdateDate(format(new Date(lastDate * 1000), 'PPP'))
    }

    if (starRatings) {
      // Get the keys
      // Find unique products
      const lastWeek = starRatings[starRatings.length - 1]

      // Add objects for initial form data
      const newFormData = {}
      const uniqueLabels = new Set()
      Object.keys(lastWeek).forEach((key) => {
        if (key !== 'id' && key !== 'createdAt') {
          uniqueLabels.add(key)
          newFormData[key] = { reviews: '', stars: '' }
        }
      })

      let temp = Array.from(uniqueLabels)

      // Sort
      const orderBy = [
        'Active Bottle',
        'Everyday Bottle',
        'Smart Bottle',
        'Soda Maker',
      ]

      // Order the data by the orderBy Arr
      temp.sort((a, b) => orderBy.indexOf(a) - orderBy.indexOf(b))
      allLabels.current = JSON.parse(JSON.stringify(temp))

      initialFormData.current = newFormData
      setFormData(newFormData)
    }
  }, [starRatings, complaints])

  const handleFormUpdate = (e) => {
    const value = e.target.value
    const product = e.target.name.split('-')
    const item = product[0]
    const type = product[1]

    setFormData((prev) => {
      return {
        ...prev,
        [item]: {
          ...prev[item],
          [type]: parseFloat(value),
        },
      }
    })
  }

  const handleSubmitWeeklyData = async () => {
    if (starRatings.some((el) => el.id === weekNumber)) {
      setFormError('An entry for that week already exists.')
      return
    }

    try {
      await addDocument(weekNumber, formData)

      // Reset form
      setFormData(initialFormData.current)
      setWeekNumber('')
      setFormError('')
      setShowNewEntriesModal(false)
    } catch (error) {
      setFormError(error)
    }
  }

  const handleDeleteLastWeek = async () => {
    try {
      const lastID = starRatings.pop().id
      await deleteDocument(lastID)
      setShowDeleteLastWeekEntryModal(false)
    } catch (error) {
      setFormError(error)
    }
  }

  return starRatings && complaints && user ? (
    <Container fluid className="my-4">
      <h5>Last update: {updateDate}</h5>
      {user && user.email === 'raina.wang@redharp.com' && (
        <Row className="d-flex justify-content-center mt-4">
          <Col xs={6} className="">
            <Button
              className="w-100"
              onClick={() => setShowNewEntriesModal(true)}
            >
              Add weekly entry
            </Button>
          </Col>
          <Col xs={6} className="">
            <Button
              className="w-100"
              variant="danger"
              onClick={() => setShowDeleteLastWeekEntryModal(true)}
            >
              Remove latest weekly entry
            </Button>
          </Col>
        </Row>
      )}

      <Row className="my-4">
        <Col xs={12} lg={6} className="mb-4 mb-lg-0">
          <StarRatings reviewData={starRatings} colors={colors} />
        </Col>
        <Col xs={12} lg={6} className="d-flex">
          <Row className="align-content-between">
            <Col xs={12} className="mb-4">
              <StarRatingsDelta reviewData={starRatings} colors={colors} />
            </Col>
            <Col xs={12} className="">
              <WeeklyRatingsCount reviewData={starRatings} colors={colors} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={8}>
          <WeeklyProductComplaints complaints={complaints} user={user} />
        </Col>
      </Row>

      <Modal
        centered
        size="lg"
        show={showNewEntriesModal}
        onHide={() => setShowNewEntriesModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add new Weekly Amazon Reviews entry</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form onSubmit={handleSubmitWeeklyData}>
            <Row>
              <Col xs={6} className="mx-auto mt-2 mb-4">
                <Form.Control
                  type="number"
                  name="active-reviews"
                  placeholder="Week number"
                  onChange={(e) => setWeekNumber(e.target.value)}
                  value={weekNumber || ''}
                  required
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              {allLabels.current.length > 0 &&
                allLabels.current.map((label, ind) => (
                  <Col xs={4} key={label}>
                    <Form.Label>
                      <strong>{label}</strong>
                    </Form.Label>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        className="mb-3"
                        type="number"
                        name={`${label}-reviews`}
                        placeholder="Reviews"
                        onChange={handleFormUpdate}
                        value={formData[label].reviews || ''}
                        required
                      />
                      <Form.Control
                        type="number"
                        name={`${label}-stars`}
                        placeholder="Stars"
                        onChange={handleFormUpdate}
                        value={formData[label].stars || ''}
                        required
                      />
                    </Form.Group>
                  </Col>
                ))}
              {formError && (
                <p className="p-2 mb-0 bg-danger rounded text-white">
                  {formError}
                </p>
              )}

              {/* {isPending ? (
              <Button
                className="mt-3 w-100"
                variant="primary"
                type="submit"
                disabled
              >
                <Spinner animation="border" variant="white" />
              </Button>
            ) : (
              <Button className="mt-3 w-100" variant="primary" type="submit">
                Log in
              </Button>
            )}

            {error && (
              <p className="border border-danger p-2 rounded bg-danger text-white fw-bold">
                {error}
              </p>
            )} */}
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmitWeeklyData}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        size="lg"
        show={showDeleteLastWeekEntryModal}
        onHide={() => setShowDeleteLastWeekEntryModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Weekly Amazon Reviews entries</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          Are you sure you would like to remove the latest weekly entry?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteLastWeek}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  ) : (
    <Container fluid className="mb-4">
      <Row className="vh-100">
        <Col className="d-flex align-items-center">
          <div className="mx-auto pb-3">
            <Spinner animation="border" variant="primary" />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

import React, { useState } from 'react'
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from 'react-bootstrap'

import { useLogin } from '../../hooks/useLogin'

export default function Login() {
  const { login, isPending, error } = useLogin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [showModal, setShowModal] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <Container>
      <Row style={{ height: '100vh' }}>
        <Col className="d-flex align-items-center justify-content-center">
          <Card
            className="py-4 px-5"
            style={{ width: '450px', borderRadius: '10px' }}
          >
            <h2 className="text-center mt-3 mb-4">Log in</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <strong>Email address</strong>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>

              <Form.Group className="mb-3 mt-3" controlId="formBasicPassword">
                <Form.Label>
                  <strong>Password</strong>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </Form.Group>

              {isPending ? (
                <Button
                  className="mt-3 w-100"
                  variant="primary"
                  type="submit"
                  disabled
                >
                  <Spinner animation="border" variant="white" />
                </Button>
              ) : (
                <Button className="mt-3 w-100" variant="primary" type="submit">
                  Log in
                </Button>
              )}

              <p className="mt-3 text-end">
                <Button variant="link" onClick={() => setShowModal(true)}>
                  Forgot password?
                </Button>
              </p>

              {error && (
                <p className="border border-danger p-2 rounded bg-danger text-white fw-bold">
                  {error}
                </p>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please contact Raina from Red Harp:{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="mailto:raina.wang@redharp.com"
          >
            raina.wang@redharp.com
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

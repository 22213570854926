import { Card, Col, Row } from 'react-bootstrap'

// styles
import './WeeklyRatingsCount.css'

export default function NumberRatingsDelta({ reviewData, colors }) {
  const lastWeek = reviewData[reviewData.length - 1]
  const twoWeeks = reviewData[reviewData.length - 2]

  const allLabels = new Set()
  reviewData.forEach((element) => {
    Object.keys(element).forEach((key) => {
      if (key !== 'id' && key !== 'createdAt') {
        allLabels.add(key)
      }
    })
  })

  const deltas = []

  // Loop through the one that has the most products and populate 'deltas' arr
  Object.keys(lastWeek).forEach((key) => {
    // {label, diff}
    if (key !== 'id' && key !== 'createdAt') {
      let diff = 0
      if (twoWeeks[key]) {
        diff = lastWeek[key].reviews - twoWeeks[key].reviews
      }
      deltas.push({
        label: key,
        diff,
      })
    }
  })

  // Sort
  const orderBy = [
    'Active Bottle',
    'Everyday Bottle',
    'Smart Bottle',
    'Soda Maker',
  ]

  // Order the data by the orderBy Arr
  deltas.sort((a, b) => orderBy.indexOf(a.label) - orderBy.indexOf(b.label))

  return (
    <Card className="shadow pt-3 px-3">
      <h4 className="text-center mt-2 mb-4">Weekly Ratings Count</h4>
      <Row className="weekly-ratings-delta">
        {deltas.map((review, ind) => (
          <Col className="text-center mb-3" key={review.label}>
            <Card
              className="p-2 shadow-sm card-active"
              style={{
                borderLeft: `5px solid rgb(${colors[ind]})`,
              }}
            >
              {review.label}
              <br />
              <div
                className={
                  review.diff > 0
                    ? 'change-positive'
                    : review.diff < 0
                    ? 'change-negative'
                    : 'change-none'
                }
              >
                <span className="sign">{review.diff > 0 ? '+' : ''}</span>
                <span className="change-number">{review.diff}</span>
                <br />
                <span className="text-black">
                  {lastWeek[review.label].reviews} total
                </span>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

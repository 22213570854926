import React, { useEffect, useState } from 'react'
import { useFirestore } from '../../hooks/useFirestore'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap'
import { getWeek } from 'date-fns'

ChartJS.register(ArcElement, Tooltip, Legend)

const BGColors = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
]

const borderColors = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
]
export default function WeeklyProductComplaints({ complaints, user }) {
  const { addDocument, updateDocument, deleteDocument, response } =
    useFirestore('Weekly Complaints')
  const [latestWeek, setLatestWeek] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalProductInd, setModalProductInd] = useState(0)
  const [modalComplaints, setModalComplaints] = useState(null)
  const [newComplaintText, setNewComplaintText] = useState('')

  const [formError, setFormError] = useState('')
  const weekNumber = (getWeek(new Date()) - 1).toString()
  // const weekNumber = (getWeek(new Date()) - 2).toString()

  const handleNumberInput = (e, type) => {
    const isAdd = type === '+'

    // get the complaint name and index (we already have the product name)
    const dataset = e.target.dataset
    // const complaintName = dataset.complaintName
    const complaintIndex = dataset.complaintIndex

    if (isAdd) {
      modalComplaints[complaintIndex].count++
    } else {
      modalComplaints[complaintIndex].count--
    }

    setModalComplaints([...modalComplaints])
  }

  const handleModalSubmit = async () => {
    latestWeek.products[modalProductInd].complaints = modalComplaints

    try {
      await updateDocument(weekNumber, { ...latestWeek })
      setShowModal(false)
    } catch (error) {}
  }

  const addNewComplaint = (e) => {
    if (newComplaintText !== '') {
      e.preventDefault()

      const newComplaint = { text: newComplaintText, count: 1, prevCount: 0 }
      modalComplaints.push(newComplaint)
      setModalComplaints([...modalComplaints])
      setNewComplaintText('')
    }
  }

  const initModal = (product, productInd) => {
    setModalTitle(`${product.name} complaints`)
    setModalProductInd(productInd)

    const newComplaints = product.complaints.map((c) => ({ ...c }))
    setModalComplaints(newComplaints)
    setShowModal(true)
  }

  useEffect(() => {
    // get the latest report to display - start with current week
    let thisWeeksData = complaints.find((el) => el.id === weekNumber)

    if (!thisWeeksData) {
      // We're in a new week, so get what's there
      thisWeeksData = complaints[0]

      // for the complaints and complaint counts, move them to the entry 'prevCount'
      thisWeeksData.products.forEach((p) => {
        p.complaints.forEach((c) => {
          c.prevCount = c.count
        })
      })

      // Make a backup
      addDocument('backup', { ...thisWeeksData, id: weekNumber })

      // Create a document for the current week
      addDocument(weekNumber, { ...thisWeeksData, id: weekNumber })

      // Also delete document of last week
      const prevWeekNum = (parseInt(weekNumber) - 1).toString()
      if (complaints.length > 0) {
        deleteDocument(prevWeekNum)
      }
    }

    // Sort the products
    thisWeeksData.products.sort((a, b) => a.index - b.index)

    // Sort complaints within products by number
    thisWeeksData.products.forEach((p) => {
      p.complaints.sort((a, b) => b.count - a.count)
    })

    setLatestWeek(thisWeeksData)
  }, [complaints, weekNumber, addDocument, deleteDocument])

  return (
    <>
      <Card className="p-4 shadow">
        <h4 className="mb-3 text-center">Weekly complaints</h4>
        <Tabs fill justify>
          {latestWeek &&
            latestWeek.products.map((p, ind) => (
              <Tab eventKey={p.name} title={p.name} key={p.name}>
                {user && user.email === 'raina.wang@redharp.com' && (
                  <Button
                    className="mt-3 w-100"
                    onClick={() => initModal(p, ind)}
                  >
                    Update
                  </Button>
                )}
                <Row>
                  <Col className="p-3">
                    <Table striped bordered hover responsive size="sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Complaint</th>
                          <th>Inc.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {p.complaints.map((complaint) => (
                          <tr key={complaint.text}>
                            <td>{complaint.count}</td>
                            <td>{complaint.text} </td>
                            <td>
                              <span className="text-primary fw-bold">
                                {complaint.prevCount === 0 ? (
                                  <Badge>New</Badge>
                                ) : (
                                  complaint.count > complaint.prevCount &&
                                  `+` + (complaint.count - complaint.prevCount)
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs={6}>
                    <Pie
                      data={{
                        labels: p.complaints.map((complaint) => complaint.text),
                        datasets: [
                          {
                            data: p.complaints.map(
                              (complaint) => complaint.count
                            ),
                            backgroundColor: BGColors.map((col) => col),
                            borderColor: borderColors.map((col) => col),
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </Tab>
            ))}
        </Tabs>
      </Card>

      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form onSubmit={null}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {modalComplaints &&
                modalComplaints.length > 0 &&
                modalComplaints.map((c, ind) => (
                  <Row className="mb-2 pb-1 border-bottom" key={c.text}>
                    <Col xs={8}>
                      <div className="complaint-text">{c.text}</div>
                    </Col>
                    <Col className="d-flex justify-content-around number-input">
                      <Button
                        size="sm"
                        className="btn-arrow"
                        onClick={(e) => handleNumberInput(e, '-')}
                        data-complaint-name={c.text}
                        data-complaint-index={ind}
                      >
                        <span className="material-symbols-outlined">
                          remove
                        </span>
                      </Button>
                      <div className="complaint-count">{c.count}</div>
                      <Button
                        size="sm"
                        className="btn-arrow"
                        onClick={(e) => handleNumberInput(e, '+')}
                        data-complaint-name={c.text}
                        data-complaint-index={ind}
                      >
                        <span className="material-symbols-outlined">add</span>
                      </Button>
                    </Col>
                  </Row>
                ))}
            </Form.Group>
            <Form.Group className="mt-4">
              <Row className="d-flex justify-content-center">
                <Col xs={10}>
                  <Form.Control
                    type="text"
                    placeholder="New complaint..."
                    value={newComplaintText}
                    onChange={(e) => setNewComplaintText(e.target.value)}
                    required
                  />
                </Col>
                <Col xs={2}>
                  <Button
                    type="submit"
                    variant="success"
                    onClick={addNewComplaint}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Form.Group>

            {formError && (
              <p className="p-2 mb-0 bg-danger rounded text-white">
                {formError}
              </p>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
